<template>
    <div>
        <the-app-bar/>
        <about/>
        <Footer/>
    </div>
</template>

<script>
import About from '../../components/about/about.vue'
import Footer from '../../components/footer.vue'
import TheAppBar from '../../components/NavBar/TheAppBar.vue'
    export default {
  components: { TheAppBar, Footer, About },
        
    }
</script>

<style lang="scss" scoped>

</style>