<template>
  <div>
    <div class="col-md-12 btn--grey text-center  py-20">
      <div class="container text-white">
        <h3 class="text-2x font-semibold " style="color: #f15825;">
          About us
        </h3>
        <h3 class="text-3xl fontsemibold" style="color: #fff;">
          A global easy, safe and secure direct payment network
        </h3>

        <p class="text-xl">
          Oonpay is a global payment network, that makes it possible for African
          immigrants living in America and Europe to make direct payment for
          services rendered to their family and friends’ resident in Africa.
        </p>
      </div>
    </div>


    <div class="container mt-5">
      <div class="row">


        <div class="col-md-6">
          <img src="../../assets/images/updates/oonpaymap.jpeg" alt="" />
        </div>
        <div class="col-md-6 py-8">
          <h3 class="text-2x font-semibold ">
            Our Mission
          </h3>
          <h3 class="text-3xl fontsemibold">
            We are bridging the gap
          </h3>
          <p class="text-lg">
            To become the one stop direct payment platform for Africans in the
            diaspora. Reduce the time It takes for businesses in Africa to receive
            and render services to their global clients. Create a transparent and
            secure payment system that can be trusted by the global community for
            payment to African businesses.
          </p>
        </div>

        <div class="col-md-6 pt-20">
          <h3 class="text-2x font-semibold ">
            The Access and Payment Problem
          </h3>
          <p class="text-lg">
            It takes a long time for time sensitive payments to reach health care
            providers in Africa. African immigrants in the USA and Europe use
            unreliable 3rd party friends and family members to pay for medical and
            other related bills for sick relatives in Africa.
          </p>
        </div>

        <div class="col-md-6">
          <img src="../../assets/images/updates/oonpaytime.jpeg" alt="" />
        </div>

        <div class="col-md-6">
          <img src="../../assets/images/updates/oonpay_business.jpeg" alt="" />
        </div>
        <div class="col-md-6  py-8">
          <h3 class="text-2x font-semibold ">
            Our Solution
          </h3>
          <h3 class="text-lg text-left fontsemibold">
            Ensure that time sensitive payments are not delayed in health care
            related needs for family and friends in Africa.
          </h3>
          <p class="text-lg text-left">
            Become the one stop direct payment platform for Africans in the
            diaspora. Reduce the time it takes for businesses in Africa to receive
            and render services to their global clients. Create a transparent and
            secure payment system that can be trusted by the global community for
            payment to African businesses.
          </p>
          <ul class=" text-left text-lg">
            <li>
              Hospital admission fees
            </li>
            <li>Emergency ambulatory service</li>
            <li>Urgent medical needs</li>
            <li>Medications</li>
          </ul>
        </div>
      </div>

      <div class="row  my-10">
        <div class="col-md-4 justify-center  py-12">
          <img src="../../assets/images/inner-img/undraw.png" width="200" height="200" class="mx-auto" alt="" />
          <h3 class="text-2xl py-4 text-center">
            Oonpay Unique #paytag Invoicing System
          </h3>
        </div>
        <div class="col-md-4 py-12">
          <img src="../../assets/images/inner-img/payments.png" class="mx-auto" alt="" />
          <h3 class="text-2xl py-4 text-center">
            Oonpay Pre-Paid Health Care Packs
          </h3>
        </div>
        <div class="col-md-4 py-12">
          <img src="../../assets/images/inner-img/code_qr_qr code.png" width="200" height="200" class="mx-auto"
            alt="" />
          <h3 class="text-2xl py-4 text-center">
            Oonpay QR-Code Instant Payment Method
          </h3>
        </div>
      </div>

      <div class="row my-10 py-32" style="border:1px solid red">
        <div class="col-md-7"></div>
        <div class="col-md-5"></div>
      </div>

      <div class="row my-10">
        <div class="col-md-12">
          <h3 class="text-xl font-semibold ">Our Offices</h3>
          <h4 class="text-2x font-semibold ">
            We are active in the following locations
          </h4>
        </div>
        <div class="col-md-4">
          <img src="../../assets/images/inner-img/ghana.jpg" class="mx-auto" alt="" />
          <h3 class="text-xl font-semibold mt-4">Ghana Offices</h3>
          <span class="text-lg"> 7th Floor(REGUS) Atlantic Tower</span> <br />
          <span class="text-lg">Plot 16, Airport City Accra Ghana</span> <br />
          <span class="text-lg">+233 536157651</span> <br>
          <span class="text-lg"> +233 501965656</span>

        </div>
        <div class="col-md-4">
          <img src="../../assets/images/inner-img/nigeria.jpg" class="mx-auto" alt="" />
          <h3 class="text-xl font-semibold mt-4">Nigeria Offices</h3>
          <span class="text-lg">Plot 16 Maygray Td Uphill</span> <br />
          <span class="text-lg">Area B Ext Nyanya</span> <br />
          <span class="text-lg">+234 8161523993 </span>
        </div>
        <div class="col-md-4">
          <img src="../../assets/images/inner-img/us.jpg" class="mx-auto" alt="" />
          <h3 class="text-xl font-semibold mt-4">USA Offices</h3>
          <span class="text-lg">10650 Culebra road</span> <br />
          <span class="text-lg">San Antonio Texas 78251</span> <br>
          <span class="text-lg">+ 1-832-716-3892</span>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.btn--grey {
  background: #0c4369;
  
}
</style>
